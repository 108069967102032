import HTTPService from './httpService';

class LoginService extends HTTPService {
  constructor() {
    super('');
  }

  async login(credentials) {
    try {
      const res = await this.post('auth/', {
        email: credentials.email.toLowerCase().trim(),
        password: credentials.password.toLowerCase().trim(),
        recaptchaToken: 'n/a',
        // TODO: add recaptcha credentials here as well.
      });
      return res.json();
    } catch (e) {
      this.reportClientError(e);
      return [];
    }
  }
}

export default new LoginService();
