import React, { useEffect, useLayoutEffect } from 'react';
import LoginView from './containers/LoginView';

import { create } from 'jss';
import rtl from 'jss-rtl';
import {
  createMuiTheme,
  StylesProvider,
  jssPreset,
  ThemeProvider,
  CssBaseline,
  Zoom,
} from '@material-ui/core';
import './App.css';
import logo from './logo.png';
import { isDev, isProd, isStage } from './utils/envUtils';

const jssInsertionPointComment = 'jss-insertion-point';

const theme = createMuiTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main: '#d60000',
      light: '#ff5131',
      dark: '#9c0000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#424242',
      light: '#6d6d6d',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: document.getElementById(jssInsertionPointComment),
});

function LandingPageProvider({ children }) {
  useLayoutEffect(() => {
    document.body.setAttribute('dir', 'rtl');
  }, []);
  return (
    <>
      <CssBaseline />
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StylesProvider>
    </>
  );
}

function App() {
  return (
    <LandingPageProvider>
      <div className="mainContainer">
        <img src={logo} className="pulse-logo" alt="logo" />
        <LoginView />
      </div>
      {!isProd() && (
        <>
          <div className="sponsorBanner">GUYUVA</div>
          <div className="versionBanner">
            {isStage() && 'סביבת הכנה למבצעי'}
            {isDev() && 'סביבת פיתוח - לא מבצעי!'}
          </div>
        </>
      )}
    </LandingPageProvider>
  );
}

export default App;
