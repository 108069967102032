import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';

export default function SimpleTextField({
  name,
  control,
  label,
  required = false,
  variant = 'outlined',
  defaultValue = '',
  autoComplete = 'off',
  rules = {},
  helperText = '',
  error = false,
  type = 'text',
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  const isPasswordField = type === 'password';
  return (
    <Controller
      {...props}
      required={required}
      variant={variant}
      label={label}
      name={name}
      control={control}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      as={TextField}
      rules={rules}
      helperText={error !== false ? error?.message : helperText}
      error={error !== false}
      InputProps={{
        endAdornment: isPasswordField ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ) : null,
        type: isPasswordField ? (showPassword ? 'text' : 'password') : type,
      }}
    />
  );
}
