import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import SimpleTextField from '~/components/forms/SimpleTextField';
import LoginService from '~/services/rest/loginService';

import styles from './LoginView.module.css';

export default function LoginView() {
  const [ formError, setFormError ] = useState(false);
  const { handleSubmit, reset, control, errors, formState } = useForm({
    defaultValues: { lessonTime: new Date() },
    mode: 'onChange',
  });

  const submitForm = async (data) => {
    setFormError(false);
    const details = await LoginService.login(data);
    if (!details)
      return setFormError('התרחשה תקלה בשליחת הנתונים לשרת. אנא נסו שוב.');
    if (!details.success) return setFormError(details.error);

    location.href = '/';
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(submitForm)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <div className={styles.fieldsContainer}>
        <div>
          <SimpleTextField
            label="אימייל"
            name="email"
            type="email"
            control={control}
            required
            rules={{
              required: true,
              pattern: {
                // Thanks to https://stackoverflow.com/a/201378
                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                message: 'המייל אינו תקין!',
              },
            }}
            error={errors.email}
            fullWidth
            classes={{ root: styles.textField }}
          />
        </div>
        <div>
          <SimpleTextField
            label="סיסמא"
            name="password"
            type="password"
            control={control}
            required
            rules={{ required: true }}
            error={errors.password}
            className={styles.textField}
          />
        </div>
        {/* <div>RECAPTCHA</div> */}
        <div>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(submitForm)}
            disabled={!formState.isValid || formState.isSubmitting}
          >
            כניסה
          </Button>
        </div>
      </div>
    </>
  );
}
