/* eslint-disable no-restricted-globals */
class HTTPService {
  BASE_DOMAIN = `${location.protocol}//${location.hostname}${
    location.port ? ":" + location.port : ""
  }`;
  BASE_URL = `${this.BASE_DOMAIN}/api/`;

  GET_HEADERS = {
    credentials: "same-origin",
  };
  POST_HEADERS = {
    ...this.GET_HEADERS,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: null,
  };
  PUT_HEADERS = {
    ...this.POST_HEADERS,
    method: "PUT",
  };
  DELETE_HEADERS = {
    ...this.POST_HEADERS,
    method: "DELETE",
  };

  constructor(apiEndpoint) {
    this.apiEndpoint = apiEndpoint;
  }

  getApiUrl() {
    return `${this.BASE_URL}${this.apiEndpoint ? `${this.apiEndpoint}/` : ""}`;
  }

  async get(params = "") {
    const url = `${this.getApiUrl()}${params}`;
    return fetch(url, {
      ...this.GET_HEADERS,
    });
  }

  async post(params = "", body) {
    const url = `${this.getApiUrl()}${params}`;
    return fetch(url, {
      ...this.POST_HEADERS,
      body: JSON.stringify(body),
    });
  }

  async put(params = "", body) {
    const url = `${this.getApiUrl()}${params}`;
    return fetch(url, {
      ...this.PUT_HEADERS,
      body: JSON.stringify(body),
    });
  }

  async delete(params = "", body) {
    const url = `${this.getApiUrl()}${params}`;
    return fetch(url, {
      ...this.DELETE_HEADERS,
      body: JSON.stringify(body),
    });
  }

  async reportClientError(error) {
    // TODO: Implement reporting a client error to the server - with an error-token each session to avoid pushing user made - up errors
    console.error(error);
  }
}

export default HTTPService;
